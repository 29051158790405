
import React from 'react'
import { Link } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import { getDestaques } from '../../store/api.js';
import { AppSettings } from '../../config/app-settings.js'

export default class ProductList extends React.Component {
    static contextType = AppSettings;

    constructor(props) {
		super(props)

		this.state = {
            brandData:null,
            open:false,
            carrinho:false,
			modalPosItem: false,
            
			produto: null,
            opcionais: [],
            adicionais: [],
            destaques: [],
            screenSize: "mobile",
            opcionaisSelecionados: [],
            adicionaisSelecionados: [],
            variacao:null,
            complemento:null,
		}

        this.addCarrinho = this.addCarrinho.bind(this)
	}

    async componentDidMount() {
        const destaques = await getDestaques()
        this.setState({destaques: destaques.data.sort((a,b) => a.prioridade - b.prioridade)})
		this.setState({screenSize: this.context.screenSize, open: this.context.open})
        this.setState({brandData:this.context.brand,carrinho:this.context.carrinho})
	}

    verifyDays(value) {
		let result = false
		const d = new Date()

		const weekday = ["0x0000001","0x0000010","0x0000100","0x0001000","0x0010000","0x0100000","0x1000000"]
		const binario = '0x'+(value >>> 0).toString(2).padStart(7, 0)

		if (!value) result = true
		else result = !!(weekday[d.getDay()] & binario)
		
		return result
	}

	price(item){
        const valor = this.context.delivery && item.valor_delivery ? item.valor_delivery : item.valor
        const valorPromo = this.context.delivery && item.valor_promocional_delivery ? item.valor_promocional_delivery : item.valor_promocional
        
        let variacoes = this.context.delivery ? item.variacao.filter( (e) => e.b_delivery === 1 ) : item.variacao.filter( (e) => e.b_delivery === 0 )
        let result = "R$ "+valor
        
        variacoes = variacoes.sort((a,b) => {
            return parseFloat(a.valor) - parseFloat(b.valor)
        })
       
        if(item.b_personalizado && variacoes && variacoes.length > 0) {
            result = "A partir de R$ "+variacoes[0].valor
        } else if(item.b_promocao && valorPromo && this.verifyDays(item.dias_promocao)) {
            result = "De R$ "+valor+" por R$ "+valorPromo
        } else if(item.b_promocao_delivery && valorPromo && this.verifyDays(item.dias_promocao)) {
            result = "De R$ "+valor+" por R$ "+valorPromo
        }

		return result
	}

    async detalheProduto(event, item) {
		event.preventDefault()

        console.log(item)

		if (!this.state.modalPosItem) {
			const opcionais = item.opcionais
			const adicionais = item.adicionais

			this.setState({ produto: item, modalPosItem: true, opcionais: opcionais, adicionais: adicionais })
		} else {
			this.setState({ produto: null, modalPosItem: false, opcionais: [], adicionais: [] })
		}
	}

    getImagem(item){
        return item.imagem ? "url(https://grubupload.s3.amazonaws.com/"+item.imagem.imagem+")" : "url(https://grubicons.s3.us-east-1.amazonaws.com/foto-padrao-grub-digital.jpg)"
    }

    async handlerScroll(event) {
		this.setState({ scrollModalPosition: event.target.scrollTop })
	}

    handleOpcionais(item, tipo = true){
		let op = tipo ? this.state.opcionaisSelecionados : this.state.adicionaisSelecionados
		
		if ( op.find(i => {return i.nome === item.nome}) ){
			op = op.filter(element => element !== item)
		} else {
			op.push(item)
		}

		const data = tipo ? {opcionaisSelecionados: op} : {adicionaisSelecionados: op}
		
		this.setState( data )
	}

	handleComentario(valor){
		this.setState( {complemento: valor} )
	}

    addCarrinho(){
        const produto = this.state.produto
        let price_tag = produto.b_promocao && produto.valor_promocional && this.verifyDays(produto.dias_promocao) ? parseFloat(produto.valor_promocional) : parseFloat(produto.valor)

		if ( produto.b_personalizado && produto.variacao.length > 0 ) {
			price_tag = parseFloat(this.state.variacao.valor)
		}

        if (!this.state.variacao && this.context.delivery && (produto.valor_promocional_delivery || produto.valor_delivery)){
            price_tag = produto.b_promocao_delivery && produto.valor_promocional_delivery && this.verifyDays(produto.dias_promocao) ? parseFloat(produto.valor_promocional_delivery) : parseFloat(produto.valor_delivery)
        }

		const valorAdicionais = this.state.adicionaisSelecionados.reduce( (total, i) => {return parseFloat(total) + i.valor ? parseFloat(i.valor) : 0}, 0 )

		const obj = {
			nome: produto.nome,
			id_produto: produto.id,
			imagem: produto.imagem,
			quantidade:1,
			variacao:this.state.variacao ? [this.state.variacao] : [],
			valor: price_tag,
			opcionais: this.state.opcionaisSelecionados,
			adicionais: this.state.adicionaisSelecionados,
			totalAdicional: valorAdicionais,
			complemento: this.state.complemento,
			valorTotal: (parseFloat(price_tag) + parseFloat(valorAdicionais)).toFixed(2)
		}

        this.context.addCarrinho(obj)
        this.setState({ modalPosItem:false, variacao: null, opcionaisSelecionados:[], adicionaisSelecionados:[], complemento: null })
    }
    
    render() {
        return (
            <div className="product-row">
                {this.state.destaques.length > 0 && this.context.delivery && !this.props.filter && this.state.destaques.map((d, i) =>
                    <div className='row'>
                        <h3 className="mt-3 mb-3">{d.nome}</h3>
                        <div className={i === 0 ? 'row cardList' : 'row'}>
                            {d.produtos.map((item) =>
                                <div key={"product"+item.id} className="product-container" data-type={item.id_categoria}>
                                    <Link to="#" className="product" onClick={(event) => this.detalheProduto(event, item)}>

                                        <div className="img position-relative" style={{backgroundImage: this.getImagem(item)}}>
                                            {item.b_promocao && item.valor_promocional && this.verifyDays(item.dias_promocao) ? (
                                                <span className="badge bg-danger position-absolute promocao">PROMOÇÃO</span>	
                                            ) : (<></>)}
                                            <span className="badge categories bg-dark rounded-pill position-absolute" style={{bottom:"10px", left:"10px", fontSize:"0.85em"}}>{item.categoria ? item.categoria.nome.toUpperCase() : ""}</span>
                                        </div>

                                        <div className="text">
                                            <div className="title">{item.nome.toUpperCase()}</div>
                                            <div className="desc" style={{position:'relative'}} >
                                                {item.descricao}	
                                            </div>
                                            {i !== 0 && (
                                                <div className="gradiente" style={{background: 'linear-gradient(180deg, rgb(255 255 255 / 0.1) 0%, rgb(255 255 255) 100%)', height:'100%', width:'100%', position:'absolute', bottom:'0', zIndex:'10'}}></div>
                                            )}
                                            <div className="price">
                                                {this.price(item)}
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {this.state.destaques.length > 0 && !this.props.filter && this.context.delivery && (
                    <div className='col-12'>
                        <h3  className="mt-3 mb-3">Todos os produtos</h3>
                    </div>
                )}

                {this.props.lista.map((item) =>
                    <div key={"product"+item.id} className="product-container normal" data-type={item.id_categoria}>
                        <Link to="#" className="product" onClick={(event) => this.detalheProduto(event, item)}>

                            <div className="img position-relative" style={{backgroundImage: this.getImagem(item)}}>
                                {item.b_promocao && item.valor_promocional && this.verifyDays(item.dias_promocao) ? (
                                    <span className="badge bg-danger position-absolute promocao">PROMOÇÃO</span>	
                                ) : (<></>)}
                                <span className="badge categories bg-dark rounded-pill position-absolute" style={{bottom:"10px", left:"10px", fontSize:"0.85em"}}>{item.categoria ? item.categoria.nome.toUpperCase() : ""}</span>
                            </div>

                            <div className="text">
                                <div className="title">{item.nome.toUpperCase()}</div>
                                <div className="desc" style={{position:'relative'}} >
                                    {item.descricao}	
                                </div>
                                <div className="gradiente" style={{background: 'linear-gradient(180deg, rgb(255 255 255 / 0.1) 0%, rgb(255 255 255) 100%)', height:'100%', width:'100%', position:'absolute', bottom:'0', zIndex:'10'}}></div>
                                <div className="price">
                                    {this.price(item)}
                                </div>
                            </div>

                        </Link>
                    </div>
                )}

                <Modal isOpen={this.state.modalPosItem} size="lg" toggle={(event) => this.detalheProduto(event, this.state.produto.id)} modalClassName="modal-pos-item">
                    <ModalBody className="p-0" style={{position:"relative", height:"100%", overflow:"hidden"}}>
                            {this.state.screenSize === "mobile" && this.state.produto && (
                                <div className="pos-product" onScroll={(e) => {this.handlerScroll(e)}} style={{overflow:"scroll"}}>
                                    <div className="pos-product-img" style={this.state.scrollModalPosition > 50 ? { transition: "height 1s", height:"20%" } : { transition: "height 1s", height:"50%"} } onClick={(event) => this.detalheProduto(event, this.state.produto.id)}>
                                        <div className="img" style={{backgroundImage: "url(https://grubupload.s3.amazonaws.com/"+this.state.produto.imagem.imagem+")"}}></div>
                                    </div>
                                    <div className="pos-product-info" style={{overflow: "hidden", minHeight: "88%", height: "auto"}}>
                                        <div className="title">{this.state.produto.nome.toUpperCase()}</div>
                                        <div className="desc">{this.state.produto.descricao}</div>
                                        <div className="price">{this.price(this.state.produto)}</div>
                                        
                                        {this.state.produto.b_personalizado && this.state.produto.variacao.length > 0 && (
                                            <div className="option-row" style={{marginBottom:"50px"}}>
                                                <div className="option-list">
                                                    {this.state.produto.variacao.map( (item) => (
                                                        <>
                                                            {this.context.delivery && item.b_delivery == 1 && (
                                                                    <div key={"variacao_"+item.nome} className="option" onClick={() => { this.setState({variacao: item}) }}>
                                                                        <label className="option-label" htmlFor="size3" style={this.state.variacao === item ? {borderColor:'#0078ff'} : {}}>
                                                                            <span className="option-text">{item.nome}</span>
                                                                            <span className="option-price">R$ {item.valor}</span>
                                                                        </label>
                                                                    </div>
                                                            )}

                                                            {!this.context.delivery && item.b_delivery == 0 && (
                                                                    <div key={"variacao_"+item.nome} className="option" onClick={() => { this.setState({variacao: item}) }}>
                                                                        <label className="option-label" htmlFor="size3" style={this.state.variacao === item ? {borderColor:'#0078ff'} : {}}>
                                                                            <span className="option-text">{item.nome}</span>
                                                                            <span className="option-price">R$ {item.valor}</span>
                                                                        </label>
                                                                    </div>
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        {this.state.opcionais.length > 0 && (
                                            <div className="option-row">
                                                <h4>Deseja retirar algum opcional?</h4>

                                                <div className="option-list">
                                                    {this.state.opcionais.map( (item) => {
                                                        return (
                                                            <div key={"variacao_"+item.nome} className="option" onClick={() => { this.handleOpcionais(item) }}>
                                                                <label className="option-label" htmlFor="size3" style={this.state.opcionaisSelecionados.find((i) => {return i.nome === item.nome}) ? {borderColor:'#0078ff'} : {}}>
                                                                    <span className="option-text"> Retirar {item.nome} </span>
                                                                </label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )}

                                        {this.state.adicionais.length > 0 && (	

                                            <div className="option-row">
                                                <h4>Deseja incluir algum adicional?</h4>

                                                <div className="option-list">
                                                    {this.state.adicionais.map( (item) => {
                                                        return (
                                                            <div key={"variacao_"+item.nome} className="option" onClick={() => { this.handleOpcionais(item, false) }}>
                                                                <label className="option-label" htmlFor="size3" style={this.state.adicionaisSelecionados.find((i) => {return i.nome === item.nome}) ? {borderColor:'#0078ff'} : {} }>
                                                                    <span className="option-text"> {item.nome} </span>
                                                                    {item.valor && (
                                                                        <span className="option-price">R$ {item.valor}</span>
                                                                    )}
                                                                </label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        
                                        )}

                                        {this.state.carrinho && (
                                            <div className="form-floating"  style={{minHeight: "100px", overflow: "hidden"}}>
                                                <div>Alguma observação?</div>
                                                <textarea className='form-control' style={{height: "50px"}} name="complemento" onChange={(e) => this.handleComentario(e.target.value)} />
                                            </div>
                                        )}
                                    </div>

                                    <div className="btn-row" style={{position:"absolute", bottom:"0", width:"100%"}}>
                                        {this.state.brandData && this.state.brandData.loja.b_pedidos && this.state.carrinho && this.state.open ? (
                                            <>
                                                <Link to="/pos/customer-order" className="btn btn-default" style={{width:'40%', height: '100%', fontSize: "15px", paddingTop:'15px'}} onClick={(event) => this.detalheProduto(event, this.state.produto.id)}>Fechar</Link>
                                                {this.state.produto.b_personalizado && this.state.produto.variacao && this.state.produto.variacao.length > 0 && !this.state.variacao ?
                                                    <div className="btn btn-success" style={{filter:'grayscale(1)', width:'60%', height: '100%', fontSize: "15px", paddingTop:'15px'}} >Adicionar produto&nbsp; <i className="fa fa-plus fa-fw ml-2"></i></div>
                                                :
                                                    <Link to="/pos/customer-order" className="btn btn-success" style={{width:'60%', height: '100%', fontSize: "15px", paddingTop:'15px'}} onClick={(event) => { event.preventDefault(); this.addCarrinho()}}>Adicionar produto&nbsp; <i className="fa fa-plus fa-fw ml-2"></i></Link>
                                                }
                                            </>
                                        ) : (
                                            <>
                                                {!this.state.open && (
                                                    <div className="alert alert-danger fade show"  style={{ position: "absolute", bottom: "27px", width:"100%", textAlign:"center" }}>
                                                        Estamos fechados em este momento.
                                                    </div>
                                                )}
                                                <Link to="/pos/customer-order" style={{width:'100%', height:'100%', fontSize: "17px"}} className="btn btn-default" onClick={(event) => this.detalheProduto(event, this.state.produto.id)}>FECHAR PRODUTO</Link>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}

                            {this.state.screenSize === "tablet" && this.state.produto &&  (
                                <div className="pos-product">
                                    <div className="pos-product-img"  onClick={(event) => this.detalheProduto(event, this.state.produto.id)}>
                                        {this.state.produto.imagem ? (
                                            <div className="img" style={{backgroundImage: "url(https://grubupload.s3.amazonaws.com/"+this.state.produto.imagem.imagem+")"}}></div>
                                        ) : (
                                            <div className="img" style={{backgroundImage: "url(https://grubicons.s3.us-east-1.amazonaws.com/foto-padrao-grub-digital.jpg)"}}></div>	
                                        )}
                                    </div>
                                    <div className="pos-product-info">
                                        <div  style={{ overflowX: "hidden", overflowY: "auto", height:"90%"}}>
                                            <div className="title">{this.state.produto.nome.toUpperCase()}</div>
                                            <div className="desc">{this.state.produto.descricao}</div>
                                            <div className="price">{this.price(this.state.produto)}</div>
                                            
                                            {this.state.produto.b_personalizado && this.state.produto.variacao.length > 0 && (
                                                <div className="option-row">
                                                    <div className="option-list">
                                                        {this.state.produto.variacao.map( (item) => 
                                                            <>
                                                                {this.context.delivery && item.b_delivery == 1 && (
                                                                        <div key={"variacao_"+item.nome} className="option" onClick={() => { this.setState({variacao: item}) }}>
                                                                            <label className="option-label" htmlFor="size3" style={this.state.variacao === item ? {borderColor:'#0078ff'} : {}}>
                                                                                <span className="option-text">{item.nome}</span>
                                                                                <span className="option-price">R$ {item.valor}</span>
                                                                            </label>
                                                                        </div>
                                                                )}

                                                                {!this.context.delivery && item.b_delivery == 0 && (
                                                                        <div key={"variacao_"+item.nome} className="option" onClick={() => { this.setState({variacao: item}) }}>
                                                                            <label className="option-label" htmlFor="size3" style={this.state.variacao === item ? {borderColor:'#0078ff'} : {}}>
                                                                                <span className="option-text">{item.nome}</span>
                                                                                <span className="option-price">R$ {item.valor}</span>
                                                                            </label>
                                                                        </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                            {this.state.opcionais.length > 0 && (
                                                <div className="option-row">
                                                    <h4>Deseja retirar algum opcional?</h4>

                                                    <div className="option-list">
                                                        {this.state.opcionais.map( (item) => {
                                                            return (
                                                                <div key={"variacao_"+item.nome} className="option" onClick={() => { this.handleOpcionais(item) }}>
                                                                    <label className="option-label" htmlFor="size3" style={this.state.opcionaisSelecionados.find((i) => {return i.nome === item.nome}) ? {borderColor:'#0078ff'} : {}}>
                                                                        <span className="option-text"> Retirar {item.nome} </span>
                                                                    </label>
                                                                </div>
                                                            )
                                                        } )}
                                                        
                                                    </div>
                                                </div>
                                            )}

                                            {this.state.adicionais.length > 0 && (	

                                                <div className="option-row">
                                                    <h4>Deseja incluir algum adicional?</h4>

                                                    <div className="option-list">
                                                        {this.state.adicionais.map( (item) => {
                                                            return (
                                                                <div key={"variacao_"+item.nome} className="option" onClick={() => { this.handleOpcionais(item, false) }}>
                                                                    <label className="option-label" htmlFor="size3" style={this.state.adicionaisSelecionados.find((i) => {return i.nome === item.nome}) ? {borderColor:'#0078ff'} : {} }>
                                                                        <span className="option-text"> {item.nome} </span>
                                                                        {item.valor && (
                                                                            <span className="option-price">R$ {item.valor}</span>
                                                                        )}
                                                                    </label>
                                                                </div>
                                                            )
                                                        } )}
                                                        
                                                    </div>
                                                </div>
                                            
                                            )}

                                            {this.state.carrinho && (
                                                <div className="form-floating mb-15px">
                                                    <div>Alguma observação?</div>
                                                    <textarea className='form-control' name="complemento" onChange={(e) => this.handleComentario(e.target.value)}/>
                                                </div>
                                            )}
                                        </div>

                                        <div className="btn-row" style={{position:"absolute", height:"7%", bottom:"20px", width:"100%"}}>
                                            {this.state.brandData && this.state.brandData.loja.b_pedidos && this.state.carrinho && this.state.open? (
                                                <>
                                                    <Link to="/pos/customer-order" className="btn btn-default" style={{width:'40%', height: '100%', fontSize: "15px", paddingTop:'15px'}} onClick={(event) => this.detalheProduto(event, this.state.produto.id)}>Fechar</Link>
                                                    {this.state.produto.b_personalizado && this.state.produto.variacao && this.state.produto.variacao.length > 0 && !this.state.variacao ?
                                                        <div className="btn btn-success" style={{filter:'grayscale(1)', width:'60%', height: '100%', fontSize: "15px", paddingTop:'15px'}} >Adicionar produto&nbsp; <i className="fa fa-plus fa-fw ml-2"></i></div>
                                                    :
                                                        <Link to="/pos/customer-order" className="btn btn-success" style={{width:'60%', height: '100%', fontSize: "15px", paddingTop:'15px'}} onClick={(event) => { event.preventDefault(); this.addCarrinho(this.state.produto)}}>Adicionar produto&nbsp; <i className="fa fa-plus fa-fw ml-2"></i></Link>
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    {!this.state.open && (
                                                        <div className="alert alert-danger fade show" style={{ position: "absolute", bottom: "27px", width:"100%", textAlign:"center" }}>
                                                            Estamos fechados em este momento. :(
                                                        </div>
                                                    )}
                                                    <Link to="/pos/customer-order" style={{width:'100%', height:'100%', fontSize: "20px"}} className="btn btn-default" onClick={(event) => this.detalheProduto(event, this.state.produto.id)}>Fechar</Link>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}